// AudioPlayer.jsx
import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useAudioPlayer } from '../context/AudioPlayerContext';

const AudioPlayer = () => {
  const { 
    audioRef, 
    currentTrack, 
    isPlaying,
    volume,
    handleNext
  } = useAudioPlayer();

  // Handle track changes and initial playback
  useEffect(() => {
    if (audioRef.current && currentTrack) {
      const currentTime = audioRef.current.currentTime; // Store current playback position
      audioRef.current.src = currentTrack.url;
      audioRef.current.currentTime = currentTime; // Restore playback position
      
      if (isPlaying) {
        audioRef.current.play().catch(error => {
          console.error("Audio playback error:", error);
        });
      }
    }
  }, [currentTrack, isPlaying]);

  // Handle volume changes in a separate effect
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  return (
    <Box display="none">
      <audio
        ref={audioRef}
        onEnded={handleNext}
        onError={(e) => console.error("Audio error:", e)}
      />
    </Box>
  );
};

export default AudioPlayer;