import React from 'react';
import { Box, Heading, useColorMode } from '@chakra-ui/react';

const SectionTitle = ({ children }) => {
  const { colorMode } = useColorMode();
  const colorSchemes = {
    light: { bg: 'pink.700', color: 'white' },
    orange: { bg: 'purple.900', color: 'yellow' },
    dark: { bg: 'black', color: 'blue.300' },
  };

  const { bg, color } = colorSchemes[colorMode];

  return (
    <Box width="100%" textAlign="center" mb={8}>
      <Box
        bg={bg}
        py={2}
        px={4}
        borderRadius="md"
        display="inline-block"
        boxShadow="0 2px 4px rgba(0,0,0,0.1)"
      >
        <Heading as="h2" size="xl" color={color}>
          {children}
        </Heading>
      </Box>
    </Box>
  );
};

export default SectionTitle;