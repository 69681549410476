import { extendTheme } from '@chakra-ui/react';

const colors = {
  light: {
    primary: 'orange.500',
    secondary: 'white',
    background: 'yellow.100',
    text: 'black',
    accent: 'green.500',
  },
  orange: {
    primary: 'blue.500',
    secondary: 'yellow.300',
    background: 'orange.100',
    text: 'blue.900',
    accent: 'purple.500',
  },
  dark: {
    primary: 'purple.700',
    secondary: 'blue.300',
    background: 'gray.900',
    text: 'white',
    accent: 'red.500',
  },
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  colors,
  config,
  fonts: {
    heading: '"Bangers", sans-serif',
    body: '"Roboto", sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'full',
        transition: 'all 0.3s ease-in-out',
      },
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: colors[props.colorMode].background,
        color: colors[props.colorMode].text,
      },
    }),
  },
});

export default theme;