// AudioPlayerControls.jsx
import React from 'react';
import { 
  Flex, 
  IconButton, 
  Text, 
  Tooltip, 
  useColorModeValue,
  Box 
} from '@chakra-ui/react';
import { 
  BsFillPlayFill, 
  BsFillPauseFill, 
  BsSkipStartFill, 
  BsSkipEndFill
} from 'react-icons/bs';
import { useAudioPlayer } from '../context/AudioPlayerContext';
import VolumeControl from './VolumeControl';

const AudioPlayerControls = () => {
  const { 
    isPlaying, 
    togglePlayPause, 
    handlePrevious, 
    handleNext, 
    currentTrack
  } = useAudioPlayer();

  const buttonBg = useColorModeValue('white', 'gray.800');
  const buttonHoverBg = useColorModeValue('gray.100', 'gray.700');
  const iconColor = useColorModeValue('gray.800', 'white');

  return (
    <Flex align="center" mx={4}>
      <Tooltip label="Previous Track">
        <IconButton
          aria-label="Previous Track"
          icon={<BsSkipStartFill />}
          onClick={handlePrevious}
          bg={buttonBg}
          color={iconColor}
          _hover={{ bg: buttonHoverBg }}
          size="sm"
          mr={2}
        />
      </Tooltip>

      <Tooltip label={isPlaying ? 'Pause' : 'Play'}>
        <IconButton
          aria-label={isPlaying ? 'Pause' : 'Play'}
          icon={isPlaying ? <BsFillPauseFill /> : <BsFillPlayFill />}
          onClick={togglePlayPause}
          bg={buttonBg}
          color={iconColor}
          _hover={{ bg: buttonHoverBg }}
          size="sm"
          mx={2}
        />
      </Tooltip>

      <Tooltip label="Next Track">
        <IconButton
          aria-label="Next Track"
          icon={<BsSkipEndFill />}
          onClick={handleNext}
          bg={buttonBg}
          color={iconColor}
          _hover={{ bg: buttonHoverBg }}
          size="sm"
          ml={2}
        />
      </Tooltip>

      <Box mx={4}>
        <VolumeControl />
      </Box>

      <Text fontSize="sm" color={iconColor} ml={3}>
        {currentTrack?.title || 'No track selected'}
      </Text>
    </Flex>
  );
};

export default AudioPlayerControls;