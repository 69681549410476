// VolumeControl.jsx
import React from 'react';
import {
  Box,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useColorModeValue
} from '@chakra-ui/react';
import { IoMdVolumeHigh, IoMdVolumeOff, IoMdVolumeLow } from 'react-icons/io';
import { useAudioPlayer } from '../context/AudioPlayerContext';

const VolumeControl = () => {
  const { volume, setVolumeLevel } = useAudioPlayer();
  const iconColor = useColorModeValue('gray.800', 'white');

  const getVolumeIcon = () => {
    if (volume === 0) return IoMdVolumeOff;
    if (volume < 0.5) return IoMdVolumeLow;
    return IoMdVolumeHigh;
  };

  const VolumeIcon = getVolumeIcon();

  const handleVolumeChange = (newVolume) => {
    setVolumeLevel(newVolume);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <IconButton
        icon={<VolumeIcon />}
        onClick={() => handleVolumeChange(volume === 0 ? 0.5 : 0)}
        variant="ghost"
        size="sm"
        color={iconColor}
        aria-label="Toggle volume"
      />
      <Slider
        aria-label="Volume"
        min={0}
        max={1}
        step={0.01}
        value={volume}
        onChange={handleVolumeChange}
        width="20"
        colorScheme="gray"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Box>
  );
};

export default VolumeControl;