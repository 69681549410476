import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { 
  Box, 
  Flex, 
  IconButton, 
  Image, 
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { motion, AnimatePresence } from 'framer-motion';

const Carousel = memo(({ 
  slides, 
  autoplayInterval = 5000, 
  direction = 'normal',
  height = "300px"
}) => {
  const [currentIndex, setCurrentIndex] = useState(direction === 'normal' ? 0 : slides.length - 1);
  const [slideWidth, setSlideWidth] = useState(0);
  const carouselRef = useRef(null);
  const autoplayTimeoutRef = useRef(null);
  const touchStartRef = useRef(null);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const updateSlideWidth = useCallback(() => {
    if (carouselRef.current) {
      setSlideWidth(carouselRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    updateSlideWidth();
    window.addEventListener('resize', updateSlideWidth);
    return () => window.removeEventListener('resize', updateSlideWidth);
  }, [updateSlideWidth]);

  const moveToNextSlide = useCallback(() => {
    setCurrentIndex(prevIndex => {
      if (direction === 'normal') {
        return prevIndex === slides.length - 1 ? 0 : prevIndex + 1;
      }
      return prevIndex === 0 ? slides.length - 1 : prevIndex - 1;
    });
  }, [slides.length, direction]);

  useEffect(() => {
    autoplayTimeoutRef.current = setTimeout(moveToNextSlide, autoplayInterval);
    return () => clearTimeout(autoplayTimeoutRef.current);
  }, [currentIndex, moveToNextSlide, autoplayInterval]);

  const handleTouchStart = (e) => {
    touchStartRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    if (!touchStartRef.current) return;
    
    const touchEnd = e.changedTouches[0].clientX;
    const diff = touchStartRef.current - touchEnd;
    
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        moveToNextSlide();
      } else {
        setCurrentIndex(prev => 
          direction === 'normal'
            ? (prev === 0 ? slides.length - 1 : prev - 1)
            : (prev === slides.length - 1 ? 0 : prev + 1)
        );
      }
    }
    
    touchStartRef.current = null;
  };

  if (!slides?.length) {
    return <Box>No slides available</Box>;
  }

  return (
    <Box 
      ref={carouselRef}
      position="relative" 
      overflow="hidden"
      height={height}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: direction === 'normal' ? 100 : -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: direction === 'normal' ? -100 : 100 }}
          transition={{ duration: 0.3 }}
          style={{ height: '100%' }}
        >
          <Flex
            height="100%"
            width="100%"
            justify="center"
            align="center"
            px={4}
          >
            {slides[currentIndex].image ? (
              <Box position="relative" width="100%" height="100%">
                <Image
                  src={slides[currentIndex].image}
                  alt={slides[currentIndex].alt}
                  objectFit="cover"
                  width="100%"
                  height="100%"
                  borderRadius="md"
                />
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  p={4}
                  bg="rgba(0,0,0,0.7)"
                  borderBottomRadius="md"
                >
                  {slides[currentIndex].caption}
                </Box>
              </Box>
            ) : (
              slides[currentIndex].content
            )}
          </Flex>
        </motion.div>
      </AnimatePresence>

      {!isMobile && (
        <>
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => {
              clearTimeout(autoplayTimeoutRef.current);
              setCurrentIndex(prev => 
                direction === 'normal'
                  ? (prev === 0 ? slides.length - 1 : prev - 1)
                  : (prev === slides.length - 1 ? 0 : prev + 1)
              );
            }}
            position="absolute"
            left={2}
            top="50%"
            transform="translateY(-50%)"
            zIndex={2}
            bg="blackAlpha.700"
            color="white"
            _hover={{ bg: "blackAlpha.800" }}
          />
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => {
              clearTimeout(autoplayTimeoutRef.current);
              moveToNextSlide();
            }}
            position="absolute"
            right={2}
            top="50%"
            transform="translateY(-50%)"
            zIndex={2}
            bg="blackAlpha.700"
            color="white"
            _hover={{ bg: "blackAlpha.800" }}
          />
        </>
      )}
    </Box>
  );
});

Carousel.displayName = 'Carousel';

export default Carousel;