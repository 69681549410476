import React from 'react';
import { Box, Text, Button, Flex, Link, VStack, useColorMode } from '@chakra-ui/react';
import SectionTitle from '../shared/SectionTitle';
import { shineAnimation } from '../../constants/animations';

const ContractSection = () => {
  const { colorMode } = useColorMode();
  
  const colorSchemes = {
    light: {
      primary: 'orange.500',
      secondary: 'white',
      background: 'yellow.100',
      text: 'black',
      accent: 'green.500',
    },
    orange: {
      primary: 'blue.500',
      secondary: 'yellow.300',
      background: 'orange.100',
      text: 'blue.900',
      accent: 'purple.500',
    },
    dark: {
      primary: 'purple.700',
      secondary: 'blue.300',
      background: 'gray.900',
      text: 'white',
      accent: 'red.500',
    },
  };

  const colors = colorSchemes[colorMode];

  return (
    <Box
      as="section"
      id="contract-address"
      textAlign="center"
      bg={colors.primary}
      borderRadius="xl"
      p={8}
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top="-50%"
        left="-50%"
        width="200%"
        height="200%"
        background={`repeating-linear-gradient(45deg, ${colors.primary}33, ${colors.primary}33 10px, ${colors.accent}33 10px, ${colors.accent}33 20px)`}
        sx={{
          animation: `${shineAnimation} 20s linear infinite`
        }}
      />
      
      <Box position="relative" zIndex={2}>
        <SectionTitle>Token</SectionTitle>
        {/* Goku Image Section */}
        <Box mt={8} display="flex" justifyContent="center">
          <Box
            as="img"
            src="/images/vegeta-10.jpg"
            alt="Vegeta"
            width="800px"
            height="800px"
            objectFit="cover"
            borderRadius="lg"
            boxShadow="xl"
            transition="transform 0.3s ease-in-out"
            _hover={{
              transform: 'scale(1.02)'
            }}
          />
        </Box>
        
        <Box 
          bg="rgba(255, 255, 255, 0.2)" 
          borderRadius="md" 
          p={6}
          backdropFilter="blur(10px)"
        >
          
          <VStack spacing={6} align="stretch" mb={8}>
            <Box
              p={4}
              borderRadius="lg"
              bg="rgba(0, 0, 0, 0.2)"
              backdropFilter="blur(8px)"
              transition="all 0.3s ease"
              _hover={{
                bg: "rgba(0, 0, 0, 0.3)",
                transform: "translateY(-2px)"
              }}
            >
              <Text 
                fontWeight="black" 
                fontSize="2xl" 
                color="rgb(135, 206, 250)"  // Light blue color
                mb={2}
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Contract Address
              </Text>
              <Text 
                fontSize="xl" 
                color="yellow.300" 
                fontFamily="mono"
                p={2}
                bg="rgba(0, 0, 0, 0.3)"
                borderRadius="md"
                border="0px solid"
                borderColor={colors.accent + "33"}
                _hover={{
                  color: "yellow.400",
                  bg: "rgba(0, 0, 0, 0.4)"
                }}
              >
                0x44d29Ab75245FB835ab7591d418fE50a68fa8C5E
              </Text>
            </Box>
            
            <Box
              p={4}
              borderRadius="lg"
              bg="rgba(0, 0, 0, 0.2)"
              backdropFilter="blur(8px)"
              transition="all 0.3s ease"
              _hover={{
                bg: "rgba(0, 0, 0, 0.3)",
                transform: "translateY(-2px)"
              }}
            >
              <Text 
                fontWeight="black" 
                fontSize="2xl" 
                color="rgb(135, 206, 250)"  // Light blue color
                mb={2}
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Pair
              </Text>
              <Text 
                fontSize="xl" 
                color="yellow.300"
                fontFamily="mono"
                p={2}
                bg="rgba(0, 0, 0, 0.3)"
                borderRadius="md"
                border="0px solid"
                borderColor={colors.accent + "33"}
                _hover={{
                  color: "yellow.400",
                  bg: "rgba(0, 0, 0, 0.4)"
                }}
              >
                0x150ff3811e8ab7c57137cf5ee37a78d20deeadb2
              </Text>
            </Box>
          </VStack>
          
          <Flex justifyContent="center" gap={4} flexWrap="wrap">
            <Button
              as={Link}
              href="https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/address/0x44d29Ab75245FB835ab7591d418fE50a68fa8C5E"
              target="_blank"
              rel="noopener noreferrer"
              bg={colors.accent}
              color="white"
              _hover={{ 
                transform: 'translateY(-3px)', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' 
              }}
              transition="all 0.3s ease-in-out"
            >
              Buy it on Pulsex
            </Button>
            
            <Button
              as={Link}
              href="https://dex.9mm.pro/?outputCurrency=0x44d29Ab75245FB835ab7591d418fE50a68fa8C5E&chain=pulsechain"
              target="_blank"
              rel="noopener noreferrer"
              bg={colors.accent}
              color="white"
              _hover={{ 
                transform: 'translateY(-3px)', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' 
              }}
              transition="all 0.3s ease-in-out"
            >
              Buy it on 9mm
            </Button>
          </Flex>
        </Box>

        
      </Box>
    </Box>
  );
};

export default React.memo(ContractSection);