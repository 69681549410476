// AudioPlayerContext.jsx
import React, { createContext, useContext, useState, useCallback, useRef } from 'react';

const AudioPlayerContext = createContext();

export function AudioPlayerProvider({ children }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const audioRef = useRef(null);

  const tracks = [
    {
      id: 1,
      title: "Track-001",
      artist: "Original Soundtrack",
      url: "/audio/Track-0001.mp3"
    },
    {
      id: 2,
      title: "Track-002",
      artist: "Original Soundtrack",
      url: "/audio/Track-0002.mp3"
    },
    {
      id: 3,
      title: "Track-003",
      artist: "Original Soundtrack",
      url: "/audio/Track-0003.mp3"
    },
    {
      id: 4,
      title: "Track-004",
      artist: "Original Soundtrack",
      url: "/audio/Track-0004.mp3"
    },
    {
      id: 5,
      title: "Track-005",
      artist: "Original Soundtrack",
      url: "/audio/Track-0005.mp3"
    }
  ];

  const currentTrack = tracks[currentTrackIndex];

  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  const handleNext = useCallback(() => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      const nextIndex = (currentTrackIndex + 1) % tracks.length;
      setCurrentTrackIndex(nextIndex);
      // Preserve isPlaying state when changing tracks
      if (isPlaying) {
        audioRef.current.play().catch(error => {
          console.error("Audio playback error:", error);
        });
      }
    }
  }, [currentTrackIndex, tracks.length, isPlaying]);

  const handlePrevious = useCallback(() => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      const prevIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
      setCurrentTrackIndex(prevIndex);
      // Preserve isPlaying state when changing tracks
      if (isPlaying) {
        audioRef.current.play().catch(error => {
          console.error("Audio playback error:", error);
        });
      }
    }
  }, [currentTrackIndex, tracks.length, isPlaying]);

  const setVolumeLevel = useCallback((level) => {
    const newVolume = Math.max(0, Math.min(1, level));
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  }, []);

  const value = {
    isPlaying,
    currentTrack,
    currentTrackIndex,
    volume,
    tracks,
    audioRef,
    togglePlayPause,
    handleNext,
    handlePrevious,
    setVolumeLevel,
    setCurrentTrackIndex
  };

  return (
    <AudioPlayerContext.Provider value={value}>
      {children}
    </AudioPlayerContext.Provider>
  );
}

export function useAudioPlayer() {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider');
  }
  return context;
}